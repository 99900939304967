import React from 'react'
import {
  HomeServices,
  LandingHero,
  Page,
} from '@components'

const meta = {
  title: 'home.meta.title',
  description: 'home.meta.description',
}

const Homepage = ({ pageContext }) => (
  <Page
    isHomepage
    locale={pageContext.locale}
    meta={meta}
  >
    <LandingHero />
    <HomeServices />
  </Page>
)

export default Homepage